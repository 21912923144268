// responsive
@mixin extraSmall {
	@media only screen and (max-width: 600px) {
		@content;
	}
}

@mixin smallDevices {
	@media only screen and (min-width: 600px) {
		@content;
	}
}

@mixin mediumDevices {
	@media only screen and (min-width: 768px) {
		@content;
	}
}

@mixin largeDevices {
	@media only screen and (min-width: 992px) {
		@content;
	}
}

@mixin ExtraLargeDevices {
	@media only screen and (min-width: 1200px) {
		@content;
	}
}

//general display

@mixin flexSpaceBetween{
    display: flex;
    justify-content: space-between;

}