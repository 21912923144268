@use "../abstracts/" as *;

.train_box {
	min-width: 100px;
	min-height: 50px;

	@include extraSmall {
		min-width: 25px;
		min-height: 20px;
	}
}
