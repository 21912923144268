@use "../abstracts" as *;

.app_bar_container{

    position: fixed;
	background: #fff;
	bottom: 0;
	border-top: 1px solid #e1e1e1;
	width: 100%;
	display: flex;
	justify-content: space-around;
	height: 4em;
	padding: 10px;

	div {
		cursor: pointer;
	}
	svg {
		color: #606060;
		fill: #606060;
		font-size: 30px;
	}


}