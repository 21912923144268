@use "./sass/base";
@use "./sass/components";
@use "./sass/pages";
@use "./sass/vendor";

.btn-refresh {
	background: #8a46ed;
	border: none;
	margin: 20px;
	color: white;
	min-width: 120px;
	height: 30px;
	border-radius: 8px;
	cursor: pointer;
}

h4 {
	font-weight: 600;
}
h3 {
	font-weight: 600;
}
h2 {
	font-weight: 600;
}
h1 {
	font-weight: 600;
}



.train-info{
	margin-top: 10px;
	width: 100%;
	display: flex;
	font-size: 12px;
	flex-direction: column;
	padding-inline: 30px ;
	justify-content: space-between;
}

.JourneyMainWrapper{
	margin-bottom: 64px;
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: center;

	&:first-child > div > div {
		border-radius: 12px 12px 12px 12px !important;
	}
}