$purple: #1d2b50;
$green: #3aa935;
$blue: #009ee3;
$yellow: #fcc42b;
$dark-blue: #1d2b50;
$dark-grey: #54565a;
$light-grey: #c8c8c9;
$white: #fff;
$black: #000;
$violet-smoke: #7c31890d;
$nav_color: #8A46ED;
$text_platform: #1780fb;
$journey_data_box: #f6f6f6;

