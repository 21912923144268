.facility-card-bottom {
	display: flex;
	align-items: center;

	.seat-status {
		background: #f3eaff;
		color: #8a46ed;
		border: none;
		margin-inline: 12px;
		padding-inline: 5px;
		height: 28px;
	}
}
