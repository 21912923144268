@use "../abstracts" as *;

body {
	margin: 0;
	padding: 0;
}

hr {
	border: 1px solid $light-grey;
}

.container {
	min-height: 70px;
	position: relative;
	margin-top: 50px;
	padding: 12px;
	// background-color: lightcoral;
	align-items: center;
	text-align: center;

	@include extraSmall {
		min-height: 70px;
	}

	&_train_info {
		margin-top: 30px;
		width: 100%;
		display: flex;
		justify-content: space-between;

		@include extraSmall {
			margin-top: 10px;
		}

		h2 {
			margin-left: 650px;

			@include extraSmall {
				font-size: 15px;
				margin-left: 10px;
			}
		}

		span {
			margin-right: 100px;
			font-style: $poppins;
			font-size: large;
			color: $nav_color;
			font-weight: 600;

			@include extraSmall {
				margin-right: 25px;
				font-size: 14px;
			}
		}
	}

	&_trains_container {
		display: flex;
		margin-top: 20px;
		justify-content: center;
		height: 55px;

		// background-color: lightgreen;
		gap: 10px;

		@include extraSmall {
			justify-content: flex-start;
			margin-top: 10px;
			height: 10px;
			padding-left: 12px;
			gap: 2px;
		}
	}
	&_color_codes {
		display: flex;
		margin-top: 20px;
		padding-left: 655px;
		align-items: center;
		// background-color: lightblue;

		@include extraSmall {
			margin-top: 20px;
			padding-left: 10px;
		}

		span {
			display: flex;
			margin-right: 10px;
			margin-left: 10px;
			text-align: center;
			align-items: center;

			@include extraSmall {
				margin-right: 5px;
				margin-left: 10px;
			}

			p {
				margin-left: 5px;

				@include extraSmall {
					margin-left: 5px;
					font-size: 12px;
				}
			}
		}
	}

	.train-info {
		font-size: 12px;
		font-weight: 500;
		padding-inline: 36px;
		padding-left: 655px;
		display: flex;
		flex-direction: column;
		align-items: flex-start;

		@include extraSmall {
			padding-inline: 12px;
			display: flex;
			flex-direction: column;
			align-items: flex-start;
		}
	}

	.container_appbar {
		margin-top: 50px;
	}
}
