@use "../abstracts" as *;

.journey_data_container {
	width: 500px;
	height: 75px;
	margin-top: 20px;
	margin-left: 20px;
	padding: 10px 20px;
	display: flex;
	flex-direction: column;
	gap: 20px;
	background-color: $journey_data_box;
	// background-color: lightgreen;
	border-radius: 15px;

	@include extraSmall {
		min-width: 280px;
		// width: 300px;
		max-width: 300px;
		min-height: 75px;
		// height: 80px;
		max-height: 110px;
		margin: auto;
		margin-top: 15px;
		padding: 10px 10px;
		// gap: 12px;
		background-color: #f6f6f6;
		border-radius: 10px;
	}

	&_top {
		@include flexSpaceBetween;

		h3 {
			flex-grow: 0.9;
			line-height: 18px;

			@include extraSmall {
				font-size: 14px;
				
			}
		}

		&_time {
			color: $nav_color;
			font-weight: 600;
			line-height: 18px;

			@include extraSmall {
				font-size: 12px;
				
			}
		}

		&_crossed_time {
			color: black;
			text-decoration: line-through;
			line-height: 18px;

			@include extraSmall {
				font-size: 12px;
				
			}
		}
	}

	&_down {
		@include flexSpaceBetween;

		&_departure {
			color: $nav_color;
			font-weight: 600;
			line-height: 18px;

			@include extraSmall {
				font-size: 12px;
				
			}
		}

		&_platform {
			color: $text_platform;
			line-height: 18px;
			font-weight: 500;

			@include extraSmall {
				font-size: 12px;
				
			}
		}
	}
}
