.facility-header {
	margin-top: 70px;
	height: 55px;
	line-height: 24px;
	display: flex;
	padding-inline: 30px;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	text-align: center;
}
