@use "../abstracts" as *;

.nav_container {
	width: 100%;
	position: fixed;
	top: 0;
	height: 64px;
	background-color: $nav_color;
	display: flex;
	padding: 20px 40px;
	z-index: 1;

	@include extraSmall {
		width: 100%;
		padding: 20px 20px;
	}

	&_arrow {
		color: $white;
		cursor: pointer;
		@include extraSmall {
			width: 10px;
		}
	}
	&_text {
		color: $white;
		font-style: $poppins;
		font-size: large;
		margin-left: 35px;
		font-weight: 600;
	}
}
