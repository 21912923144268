@use "../abstracts" as *;

.main_container {
	height: 100vh;

	&_data_box {
		width: 800px;
		margin: auto;
		// background-color: lightblue;
		padding: 20px 110px;
		// height: 100vh;

		@include extraSmall {
			width: 100%;
			margin: auto;
			padding: 10px 6%;
			padding-bottom: 80px;
			// height: 100vh;
		}
	}
}
