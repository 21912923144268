@use "../abstracts" as *;

.square_box{
    width: 21px;
    height: 21px;
    // background-color: brown;

    @include extraSmall {
       min-width: 10px;
       min-height: 10px;
       width: 10px;
       height: 10px;
        // height: 100vh;
    }
}