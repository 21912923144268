@use "../abstracts" as *;

.MuiDialogTitle-root {
	background: linear-gradient(90deg, $purple 0%, $purple 100%) !important;
	color: white !important;
}

.MuiButton-containedSecondary {
	color: white;
	background-color: $purple;
}

.MuiTableSortLabel-root.MuiTableSortLabel-active.MuiTableSortLabel-root.MuiTableSortLabel-active .MuiTableSortLabel-icon {
	color: $purple !important;
	opacity: 1;
}

.MuiInputLabel-animated {
	color: $black !important;
}

.MuiDialog-paperWidthSm {
	max-width: 660px !important;
}

.MuiAutocomplete-popper {
	width: 246px !important;
	margin-left: "100px" !important;
}

// .MuiInputBase-input {
// 	font: inherit;
// 	color: currentColor;
// 	width: 100%;
// 	border: 0;
// 	height: 0.44 em !important;
// 	margin: 0;
// 	display: block;
// 	padding: 6 px 0 7 px;
// 	min-width: 0;
// 	background: none;
// 	box-sizing: content-box;
// 	animation-name: mui-auto-fill-cancel;
// 	letter-spacing: inherit;
// 	animation-duration: 10ms;
// 	-webkit-tap-highlight-color: transparent;
// }
