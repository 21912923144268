@use "../abstracts" as *;

.header_container {
	width: 100%;
	height: 65px;
	position: fixed;
	display: flex;
	padding: 0 24px;
	background-color: $white;
	top: 0;
	box-shadow: 0px 0px 5px $light-grey;
	z-index: 1;
	padding-left: 60px;
	&_content {
		overflow: hidden;
		color: $black;
		max-width: 1920px;
		margin: 0;
		display: flex;
		align-items: center;
		justify-content: space-between;
		position: relative;
		z-index: 100;

		&_nav {
			height: 100vh;
			width: 100%;
			display: flex;
			justify-content: center;
			align-items: center;
			text-align: center;
		}
	}

	ul {
		display: flex;
		list-style: none;
		text-decoration: none;
		margin-left: 400px;
		font-size: 18px;
		display: none;

		li {
			text-decoration: none;
			padding: 0.75rem 1.25rem;
			font-size: 18px;
			font-weight: 600;
		}
	}

	img {
		margin-left: 35px;
		width: 120px;

		@include extraSmall {
			width: 200px;
		}
	}

	a {
		text-decoration: none;
		color: $dark-blue;
	}
}
